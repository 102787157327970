import React, { useState, useEffect } from 'react'
import './App.css';
import AgoraRTC from 'agora-rtc-sdk-ng'

const rtc = {
  client: null,
  localAudioTrack: null,
  localVideoTrack: null,
};

const options = {
  // 替换成你自己项目的 App ID。
  appId: "c5921a77fdb1453f86ff194b572d09ef",
  // 传入目标频道名。
  channel: "miechat_dev",
  token: "006c5921a77fdb1453f86ff194b572d09efIABzmLVfEAE8YsOMX3MVNacD3LCYSPQQz12Is7vGmOhepkuJWp8AAAAAEADJZeRXeHhLYgEAAQB4eEti",
};

async function startBasicCall() {
  rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  const uid = await rtc.client.join(options.appId, options.channel, options.token, null);
  console.log(uid)
  console.log("client has joined the channel")
  rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  rtc.localVideoTrack.play('playerContainer')
  await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

  rtc.client.on("user-published", async (user, mediaType) => {
    // 开始订阅远端用户。
    await rtc.client.subscribe(user, mediaType);
    console.log("subscribe success");
  
    // 表示本次订阅的是视频。
    if (mediaType === "video") {
      // 订阅完成后，从 `user` 中获取远端视频轨道对象。
      const remoteVideoTrack = user.videoTrack;
      // 动态插入一个 DIV 节点作为播放远端视频轨道的容器。
      const playerContainer = document.createElement("div");
      // 给这个 DIV 节点指定一个 ID，这里指定的是远端用户的 UID。
      playerContainer.id = user.uid.toString();
      playerContainer.style.width = "640px";
      playerContainer.style.height = "480px";
      document.body.append(playerContainer);
  
      // 订阅完成，播放远端音视频。
      // 传入 DIV 节点，让 SDK 在这个节点下创建相应的播放器播放远端视频。
      remoteVideoTrack.play(playerContainer);
  
      // 也可以只传入该 DIV 节点的 ID。
      // remoteVideoTrack.play(playerContainer.id);
    }
  
    // 表示本次订阅的是音频。
    if (mediaType === "audio") {
      // 订阅完成后，从 `user` 中获取远端音频轨道对象。
      const remoteAudioTrack = user.audioTrack;
      // 播放音频因为不会有画面，不需要提供 DOM 元素的信息。
      remoteAudioTrack.play();
    }
  });
  
  rtc.rtcClient.on('user-unpublished', (user, mediaType) => {
    if (mediaType === 'video') {
      // 获取刚刚动态创建的 DIV 节点。
      const playerContainer = document.getElementById(uid.toString())
      // 销毁这个节点。
      playerContainer.remove()
    }
  })
  
    
}

async function leaveCall() {
  // 销毁本地音视频轨道。
  rtc.localAudioTrack.close();
  rtc.localVideoTrack.close();

  // 遍历远端用户。
  rtc.client.remoteUsers.forEach(user => {
    // 销毁动态创建的 DIV 节点。
    const playerContainer = document.getElementById(user.uid);
    playerContainer && playerContainer.remove();
  });

  // 离开频道。
  await rtc.client.leave();
}

function App() {
  useEffect(() => {
    startBasicCall();
    return leaveCall();
  }, 
  [])
  
  return (
    <div className="App">
      <div id="playerContainer" className="player-container"></div>
    </div>
  );
}

export default App;
